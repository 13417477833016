@tailwind base;
@tailwind components;
@tailwind utilities;
/*@import '~antd/dist/antd.less';*/

.bjs-powered-by {
    /*display:none !important;*/
    transform: scale(0.2);
}

.highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: green !important; /* color elements as green */
}

body {
	font-family: "Microsoft Yahei";
	padding: 0;
	margin: 0;
	font-weight: normal;
	text-decoration: none;
	text-shadow: none;
}
/* .ant-legacy-form-item-control-wrapper{
    width: 100%;
} */
.homeboxs{
    height: calc(100vh - 260px) !important;
}
/* .video-react .video-react-video{
    width: 1150px !important;
    height: 660px !important;
} */

.video-react .video-react-poster{
    background-color: #77a7fe !important;
  
    margin-top: -1px !important;
}
.commontable .ant-card-head-title{
    padding: 0 !important;
}
.commontable .ant-card-head{
    padding: 0 !important;
    min-height:0 !important;
}
.commontable .ant-card-body{
    padding: 0 !important;
}

.commontable .ant-table-pagination.ant-pagination{
    margin: 10px 0px 0 0 !important;
}

.pointtable .ant-table-wrapper{
    margin: 0 !important;
}
 
.video-react{
    border-radius: 10px;
}
.gdleft {
    border-right:1px solid rgba(236, 236, 248, 1) 
}
.gdleft .ant-menu-item .imgbox{
    display: block;
    width: 16px;
    height: 16px;
    background-image: url('../src/img/left01.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.gdleft .ant-menu-item-selected .imgbox{
    background-image: url('../src/img/left02.png');
}
.gdleft .ant-menu-vertical{
    border-right:none !important;
}
.gdleft .ant-menu-item{
    background-color: #ECECF8 !important;
    border-radius: 4px !important;
    margin-bottom: 16px !important;
}
.gdleft .ant-menu-item-selected{
    background-color: #8978FD !important;
    color: #fff !important;
}
.ant-drawer{
    z-index: 500 !important;
}
.ant-drawer-mask{
    z-index: 500 !important;
}
::-webkit-scrollbar{
	width: 5px;/* 纵向滚动条*/
	height: 5px;/* 横向滚动条 */
	background-color: #fff;
  }

  
  /*定义滚动条轨道 内阴影*/
  ::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	background-color: #fff;
  }
  
  /*定义滑块 内阴影*/
  ::-webkit-scrollbar-thumb{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	background-color: #D5D5D5;
  }

  .ant-steps-item-description{
	padding-bottom: 5px !important;
  }
  .topnavbarbg{
    background:repeating-linear-gradient(to right,#8978FD,#AB9FFE) !important;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
    padding-top: 5px !important;
  }
  .navbar {
	background-image: linear-gradient(270deg, rgb(0, 39, 102) 0px,
	 rgb(18, 3, 56) 25%,
	  rgb(29, 29, 37) 50%,
	   rgb(19, 27, 24) 75%, 
	   rgb(0, 16, 7) 100%);
  }
.hidbox{
    display: none !important;
}
.ant-btn{
	line-height: 0.5715 !important;
}

.anticon svg{
	margin-top: -7px;
}
.caozuobox .anticon svg{
	margin-top: -2px !important;
}

.testwaibos{
    padding-left: 30px;
    position: relative;
    width: 250px;
    height:230px;
}
.testone{
    position: absolute;
    top: -15px;
    left: 10px;
}
.testtwo{
    position: absolute;
    top: -15px;
    right: -10px;
}
.testthree{
    position: absolute;
    bottom: 15px;
    left: 0px;
}
.testbox{
    width: 200px;
    height: 200px;
    border: 1px solid #666;
}
.neitest{
    position: relative;
}
.testnumber{
    width: 100px;
    position: absolute;
    right: -110px;
    bottom: 0px;
}
.ant-input-number{
    width: 100% !important;
    max-width: 100%;
}
.ant-input-number-group-wrapper {
    width: 100% !important;
}
.ant-collapse-item-active .ant-collapse-header{
    background:repeating-linear-gradient(to right,#8978FD,#AB9FFE) !important;
}
.twobox  .ant-collapse-header{
    background:repeating-linear-gradient(to right,rgb(172, 172, 172),rgb(220, 220, 220)) !important;
}
.twobox .ant-collapse > .ant-collapse-item > .ant-collapse-header    {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.ant-collapse-item-active .tespan{
    color: #fff;
}
.ant-collapse-item-active .ant-collapse-arrow{                                          
    color: #fff;
}
.ant-collapse-content-box{
    padding: 0 !important;
    }
.specialprogress .ant-progress-bg{
    height: 10px !important;
}

.specialprogress .ant-progress-success-bg{
    height: 10px !important;
}
.specialprogres .ant-collapse-header{
    background:#ececf8 !important;
   
}
.specialprogres span{
    color: #666 !important;
}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
    margin: 0 0 0 0 !important
}
.ant-select-arrow .anticon > svg {
    margin-top: 2px;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
  background-color:#ececf8 !important
}
.ant-picker{
    width: 100% !important;
}
.ant-image-preview-img{
    margin: 0 auto;
}
.ant-select-selector{
    height: 100% !important;
}
.ant-image-preview-img{
    display: inline-block !important;
}
.avatar-uploader>.ant-upload.ant-upload-select-picture-card{
    display: inline-block !important;
}
.avatar-uploader .ant-upload.ant-upload-select-picture-card > .ant-upload{
    display: inline-block !important;
}
.avatar-uploader > .ant-upload{
    display: inline-block !important;
    width: 180px !important;
    height: 180px !important;
}
.profile-box .ant-form-item{
    width: 100% !important;
}
.ant-message{
    top:50% !important
}